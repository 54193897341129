exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-activate-trial-js": () => import("./../../../src/pages/activate-trial.js" /* webpackChunkName: "component---src-pages-activate-trial-js" */),
  "component---src-pages-api-terms-js": () => import("./../../../src/pages/api-terms.js" /* webpackChunkName: "component---src-pages-api-terms-js" */),
  "component---src-pages-become-a-partner-js": () => import("./../../../src/pages/become-a-partner.js" /* webpackChunkName: "component---src-pages-become-a-partner-js" */),
  "component---src-pages-become-an-affiliate-js": () => import("./../../../src/pages/become-an-affiliate.js" /* webpackChunkName: "component---src-pages-become-an-affiliate-js" */),
  "component---src-pages-book-demo-c-js": () => import("./../../../src/pages/book-demo-c.js" /* webpackChunkName: "component---src-pages-book-demo-c-js" */),
  "component---src-pages-book-demo-js": () => import("./../../../src/pages/book-demo.js" /* webpackChunkName: "component---src-pages-book-demo-js" */),
  "component---src-pages-book-demo-lapsed-trial-js": () => import("./../../../src/pages/book-demo-lapsed-trial.js" /* webpackChunkName: "component---src-pages-book-demo-lapsed-trial-js" */),
  "component---src-pages-book-demo-payroll-js": () => import("./../../../src/pages/book-demo-payroll.js" /* webpackChunkName: "component---src-pages-book-demo-payroll-js" */),
  "component---src-pages-book-demo-switch-payroll-js": () => import("./../../../src/pages/book-demo-switch-payroll.js" /* webpackChunkName: "component---src-pages-book-demo-switch-payroll-js" */),
  "component---src-pages-book-demo-tip-payouts-js": () => import("./../../../src/pages/book-demo-tip-payouts.js" /* webpackChunkName: "component---src-pages-book-demo-tip-payouts-js" */),
  "component---src-pages-book-demo-v-js": () => import("./../../../src/pages/book-demo-v.js" /* webpackChunkName: "component---src-pages-book-demo-v-js" */),
  "component---src-pages-brink-referrals-terms-js": () => import("./../../../src/pages/brink-referrals-terms.js" /* webpackChunkName: "component---src-pages-brink-referrals-terms-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-clover-signup-js": () => import("./../../../src/pages/clover-signup.js" /* webpackChunkName: "component---src-pages-clover-signup-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-demo-signup-js": () => import("./../../../src/pages/demo-signup.js" /* webpackChunkName: "component---src-pages-demo-signup-js" */),
  "component---src-pages-doordash-js": () => import("./../../../src/pages/doordash.js" /* webpackChunkName: "component---src-pages-doordash-js" */),
  "component---src-pages-dpa-js": () => import("./../../../src/pages/dpa.js" /* webpackChunkName: "component---src-pages-dpa-js" */),
  "component---src-pages-graduate-referral-terms-js": () => import("./../../../src/pages/graduate-referral-terms.js" /* webpackChunkName: "component---src-pages-graduate-referral-terms-js" */),
  "component---src-pages-home-v-js": () => import("./../../../src/pages/home-v.js" /* webpackChunkName: "component---src-pages-home-v-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internal-signup-csm-js": () => import("./../../../src/pages/internal-signup-csm.js" /* webpackChunkName: "component---src-pages-internal-signup-csm-js" */),
  "component---src-pages-internal-signup-gotab-js": () => import("./../../../src/pages/internal-signup-gotab.js" /* webpackChunkName: "component---src-pages-internal-signup-gotab-js" */),
  "component---src-pages-internal-signup-js": () => import("./../../../src/pages/internal-signup.js" /* webpackChunkName: "component---src-pages-internal-signup-js" */),
  "component---src-pages-internal-signup-lead-js": () => import("./../../../src/pages/internal-signup-lead.js" /* webpackChunkName: "component---src-pages-internal-signup-lead-js" */),
  "component---src-pages-interview-signup-js": () => import("./../../../src/pages/interview-signup.js" /* webpackChunkName: "component---src-pages-interview-signup-js" */),
  "component---src-pages-make-the-switch-js": () => import("./../../../src/pages/make-the-switch.js" /* webpackChunkName: "component---src-pages-make-the-switch-js" */),
  "component---src-pages-make-the-switch-ppc-js": () => import("./../../../src/pages/make-the-switch-ppc.js" /* webpackChunkName: "component---src-pages-make-the-switch-ppc-js" */),
  "component---src-pages-marketing-iframe-js": () => import("./../../../src/pages/marketing-iframe.js" /* webpackChunkName: "component---src-pages-marketing-iframe-js" */),
  "component---src-pages-marketing-iframe-non-us-js": () => import("./../../../src/pages/marketing-iframe-non-us.js" /* webpackChunkName: "component---src-pages-marketing-iframe-non-us-js" */),
  "component---src-pages-midmarket-referral-terms-js": () => import("./../../../src/pages/midmarket-referral-terms.js" /* webpackChunkName: "component---src-pages-midmarket-referral-terms-js" */),
  "component---src-pages-pass-through-fees-js": () => import("./../../../src/pages/pass-through-fees.js" /* webpackChunkName: "component---src-pages-pass-through-fees-js" */),
  "component---src-pages-plate-js": () => import("./../../../src/pages/plate.js" /* webpackChunkName: "component---src-pages-plate-js" */),
  "component---src-pages-pricing-27443930408-js": () => import("./../../../src/pages/pricing-27443930408.js" /* webpackChunkName: "component---src-pages-pricing-27443930408-js" */),
  "component---src-pages-pricing-3-f-11-a-762-c-5-d-56-ef-5-a-6-a-3974-c-069-dfbcb-js": () => import("./../../../src/pages/pricing-3f11a762c5d56ef5a6a3974c069dfbcb.js" /* webpackChunkName: "component---src-pages-pricing-3-f-11-a-762-c-5-d-56-ef-5-a-6-a-3974-c-069-dfbcb-js" */),
  "component---src-pages-pricing-4-c-4-a-25-ab-46-d-8223-b-6042-d-0944-ff-7-dd-50-js": () => import("./../../../src/pages/pricing-4c4a25ab46d8223b6042d0944ff7dd50.js" /* webpackChunkName: "component---src-pages-pricing-4-c-4-a-25-ab-46-d-8223-b-6042-d-0944-ff-7-dd-50-js" */),
  "component---src-pages-pricing-74-b-7205-f-61194-b-16-e-5-d-61-d-8-a-56-e-7522-d-js": () => import("./../../../src/pages/pricing-74b7205f61194b16e5d61d8a56e7522d.js" /* webpackChunkName: "component---src-pages-pricing-74-b-7205-f-61194-b-16-e-5-d-61-d-8-a-56-e-7522-d-js" */),
  "component---src-pages-pricing-87-d-489-a-7087687-d-3-f-906-f-780857-b-7-ed-2-js": () => import("./../../../src/pages/pricing-87d489a7087687d3f906f780857b7ed2.js" /* webpackChunkName: "component---src-pages-pricing-87-d-489-a-7087687-d-3-f-906-f-780857-b-7-ed-2-js" */),
  "component---src-pages-pricing-9-e-8-ea-5-f-03-db-4-ea-078-d-2-c-06326-e-8-c-3-e-76-js": () => import("./../../../src/pages/pricing-9e8ea5f03db4ea078d2c06326e8c3e76.js" /* webpackChunkName: "component---src-pages-pricing-9-e-8-ea-5-f-03-db-4-ea-078-d-2-c-06326-e-8-c-3-e-76-js" */),
  "component---src-pages-pricing-ada-0-c-2-c-02653-be-4092-f-376-bd-0-ed-96254-js": () => import("./../../../src/pages/pricing-ada0c2c02653be4092f376bd0ed96254.js" /* webpackChunkName: "component---src-pages-pricing-ada-0-c-2-c-02653-be-4092-f-376-bd-0-ed-96254-js" */),
  "component---src-pages-pricing-c-js": () => import("./../../../src/pages/pricing-c.js" /* webpackChunkName: "component---src-pages-pricing-c-js" */),
  "component---src-pages-pricing-ead-96497-de-621-fdee-80-daf-74-da-0545-bb-js": () => import("./../../../src/pages/pricing-ead96497de621fdee80daf74da0545bb.js" /* webpackChunkName: "component---src-pages-pricing-ead-96497-de-621-fdee-80-daf-74-da-0545-bb-js" */),
  "component---src-pages-pricing-faq-js": () => import("./../../../src/pages/pricing-faq.js" /* webpackChunkName: "component---src-pages-pricing-faq-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-privacy-choices-js": () => import("./../../../src/pages/privacy-choices.js" /* webpackChunkName: "component---src-pages-privacy-choices-js" */),
  "component---src-pages-privacy-policy-es-js": () => import("./../../../src/pages/privacy-policy-es.js" /* webpackChunkName: "component---src-pages-privacy-policy-es-js" */),
  "component---src-pages-privacy-policy-fr-js": () => import("./../../../src/pages/privacy-policy-fr.js" /* webpackChunkName: "component---src-pages-privacy-policy-fr-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-refer-a-friend-terms-js": () => import("./../../../src/pages/refer-a-friend-terms.js" /* webpackChunkName: "component---src-pages-refer-a-friend-terms-js" */),
  "component---src-pages-refer-index-js": () => import("./../../../src/pages/refer/index.js" /* webpackChunkName: "component---src-pages-refer-index-js" */),
  "component---src-pages-refer-signup-js": () => import("./../../../src/pages/refer/signup.js" /* webpackChunkName: "component---src-pages-refer-signup-js" */),
  "component---src-pages-referral-agreement-js": () => import("./../../../src/pages/referral-agreement.js" /* webpackChunkName: "component---src-pages-referral-agreement-js" */),
  "component---src-pages-referrals-customers-js": () => import("./../../../src/pages/referrals/customers.js" /* webpackChunkName: "component---src-pages-referrals-customers-js" */),
  "component---src-pages-referrals-index-js": () => import("./../../../src/pages/referrals/index.js" /* webpackChunkName: "component---src-pages-referrals-index-js" */),
  "component---src-pages-registrarse-js": () => import("./../../../src/pages/registrarse.js" /* webpackChunkName: "component---src-pages-registrarse-js" */),
  "component---src-pages-resources-food-runner-restaurant-newsletter-signup-js": () => import("./../../../src/pages/resources/food-runner-restaurant-newsletter-signup.js" /* webpackChunkName: "component---src-pages-resources-food-runner-restaurant-newsletter-signup-js" */),
  "component---src-pages-resources-restaurant-growth-podcast-js": () => import("./../../../src/pages/resources/restaurant-growth-podcast.js" /* webpackChunkName: "component---src-pages-resources-restaurant-growth-podcast-js" */),
  "component---src-pages-resources-the-tip-jar-js": () => import("./../../../src/pages/resources/the-tip-jar.js" /* webpackChunkName: "component---src-pages-resources-the-tip-jar-js" */),
  "component---src-pages-responsible-disclosure-js": () => import("./../../../src/pages/responsible-disclosure.js" /* webpackChunkName: "component---src-pages-responsible-disclosure-js" */),
  "component---src-pages-restaurant-mobile-scheduling-apps-js": () => import("./../../../src/pages/restaurant-mobile-scheduling-apps.js" /* webpackChunkName: "component---src-pages-restaurant-mobile-scheduling-apps-js" */),
  "component---src-pages-servingwhatsnext-js": () => import("./../../../src/pages/servingwhatsnext.js" /* webpackChunkName: "component---src-pages-servingwhatsnext-js" */),
  "component---src-pages-servingwhatsnext-scholarship-js": () => import("./../../../src/pages/servingwhatsnext/scholarship.js" /* webpackChunkName: "component---src-pages-servingwhatsnext-scholarship-js" */),
  "component---src-pages-signup-bdr-js": () => import("./../../../src/pages/signup-bdr.js" /* webpackChunkName: "component---src-pages-signup-bdr-js" */),
  "component---src-pages-signup-doordash-js": () => import("./../../../src/pages/signup-doordash.js" /* webpackChunkName: "component---src-pages-signup-doordash-js" */),
  "component---src-pages-signup-influencer-js": () => import("./../../../src/pages/signup-influencer.js" /* webpackChunkName: "component---src-pages-signup-influencer-js" */),
  "component---src-pages-signup-internal-im-js": () => import("./../../../src/pages/signup-internal-im.js" /* webpackChunkName: "component---src-pages-signup-internal-im-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-signup-outbound-js": () => import("./../../../src/pages/signup-outbound.js" /* webpackChunkName: "component---src-pages-signup-outbound-js" */),
  "component---src-pages-signup-payroll-js": () => import("./../../../src/pages/signup-payroll.js" /* webpackChunkName: "component---src-pages-signup-payroll-js" */),
  "component---src-pages-signup-tip-payouts-js": () => import("./../../../src/pages/signup-tip-payouts.js" /* webpackChunkName: "component---src-pages-signup-tip-payouts-js" */),
  "component---src-pages-signup-v-js": () => import("./../../../src/pages/signup-v.js" /* webpackChunkName: "component---src-pages-signup-v-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-subject-rights-js": () => import("./../../../src/pages/subject-rights.js" /* webpackChunkName: "component---src-pages-subject-rights-js" */),
  "component---src-pages-terms-of-use-and-terms-of-service-js": () => import("./../../../src/pages/terms-of-use-and-terms-of-service.js" /* webpackChunkName: "component---src-pages-terms-of-use-and-terms-of-service-js" */),
  "component---src-pages-toast-referrals-terms-js": () => import("./../../../src/pages/toast-referrals-terms.js" /* webpackChunkName: "component---src-pages-toast-referrals-terms-js" */),
  "component---src-pages-vip-table-js": () => import("./../../../src/pages/vip-table.js" /* webpackChunkName: "component---src-pages-vip-table-js" */),
  "component---src-templates-competitor-compare-lp-js": () => import("./../../../src/templates/competitorCompareLp.js" /* webpackChunkName: "component---src-templates-competitor-compare-lp-js" */),
  "component---src-templates-custom-signup-lp-js": () => import("./../../../src/templates/customSignupLp.js" /* webpackChunkName: "component---src-templates-custom-signup-lp-js" */),
  "component---src-templates-field-sales-lp-js": () => import("./../../../src/templates/fieldSalesLp.js" /* webpackChunkName: "component---src-templates-field-sales-lp-js" */),
  "component---src-templates-franchise-lp-js": () => import("./../../../src/templates/franchiseLp.js" /* webpackChunkName: "component---src-templates-franchise-lp-js" */),
  "component---src-templates-franchise-referral-js": () => import("./../../../src/templates/franchiseReferral.js" /* webpackChunkName: "component---src-templates-franchise-referral-js" */),
  "component---src-templates-franchise-signup-js": () => import("./../../../src/templates/franchiseSignup.js" /* webpackChunkName: "component---src-templates-franchise-signup-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-partner-public-leads-js": () => import("./../../../src/templates/partnerPublicLeads.js" /* webpackChunkName: "component---src-templates-partner-public-leads-js" */),
  "component---src-templates-partner-referral-js": () => import("./../../../src/templates/partnerReferral.js" /* webpackChunkName: "component---src-templates-partner-referral-js" */),
  "component---src-templates-resource-index-js": () => import("./../../../src/templates/resourceIndex.js" /* webpackChunkName: "component---src-templates-resource-index-js" */),
  "component---src-templates-resource-js": () => import("./../../../src/templates/resource.js" /* webpackChunkName: "component---src-templates-resource-js" */),
  "component---src-templates-sticky-column-page-js": () => import("./../../../src/templates/stickyColumnPage.js" /* webpackChunkName: "component---src-templates-sticky-column-page-js" */),
  "component---src-templates-vertical-page-js": () => import("./../../../src/templates/verticalPage.js" /* webpackChunkName: "component---src-templates-vertical-page-js" */)
}

